export interface AppAdministratorTrainingsCategoriesListFormUpdate {
  id: string;
  organization_id: string;
  content: {
    name: string;
    fields: any;
  };
}

export class AppAdministratorTrainingsCategoriesListFormUpdate implements AppAdministratorTrainingsCategoriesListFormUpdate {
  id: string;
  organization_id: string;
  content: {
    name: string;
    fields: any;
  };

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      name: "",
      fields: [],
    };
  }
}