
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorTrainingsCategoriesListFormCreate} from "@/models/app/administrator/trainings/categories/list/form/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {
  Plus,
  Bottom,
  Top,
  CircleClose,
  CirclePlus,
} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Plus,
    Bottom,
    Top,
    CircleClose,
    CirclePlus,
  },
})
export default class AppAdministratorTrainingsCategoriesListFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: AppAdministratorTrainingsCategoriesListFormCreate = new AppAdministratorTrainingsCategoriesListFormCreate();
  formDataRules = {
    content: {
      name: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  addField(): void {
    this.formData.content.fields.push({
      name: "",
      property: "",
      type: "text"
    });
  }

  removeField(index: number): void {
    this.formData.content.fields = this.formData.content.fields.filter((item, key) => index !== key);
  }

  sortUpField(index: number): void {
    let element = this.formData.content.fields[index];
    this.formData.content.fields.splice(index, 1);
    this.formData.content.fields.splice(index - 1, 0, element);
  }

  sortDownField(index: number): void {
    let element = this.formData.content.fields[index];
    this.formData.content.fields.splice(index, 1);
    this.formData.content.fields.splice(index + 1, 0, element);
  }

  setProperty(index: number): void {
    this.formData.content.fields[index].property = this.formData.content.fields[index].name.toLowerCase().replace(/ /g, '-');
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/training-category/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTrainingCategories');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorTrainingsCategoriesListFormCreate();
  }
}
