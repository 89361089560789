
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppAdministratorTrainingsCategoriesListIndex} from "@/models/app/administrator/trainings/categories/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorTrainingsCategoriesListFormCreateIndexVue from "@/views/app/administrator/trainings/categories/list/form/create.vue";
import AppAdministratorTrainingsCategoriesListFormUpdateIndexVue from "@/views/app/administrator/trainings/categories/list/form/update.vue";
import AppAdministratorTrainingsCategoriesListFormRemoveIndexVue from "@/views/app/administrator/trainings/categories/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorTrainingsCategoriesListFormCreateIndexVue,
    AppAdministratorTrainingsCategoriesListFormUpdateIndexVue,
    AppAdministratorTrainingsCategoriesListFormRemoveIndexVue,
  },
})
export default class AppAdministratorTrainingsCategoriesListIndexVue extends Vue {
  isLoading: boolean | null = false;
  moment: any = null;

  trainingCategories: Array<AppAdministratorTrainingsCategoriesListIndex> = [];

  async getTrainingCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/training-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainingCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getTrainingCategories();
  }
}
